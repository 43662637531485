<template>
	<!-- 报检汇总 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title"> 合同变更条记录 </div>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row
					style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="19" style="margin-left:20px">
						<el-form-item label="合同名称" class="selFormItem">
							<el-input class="selSearchInput" v-model="contractName" type="input" rows="1"
								placeholder="请填写"></el-input>
						</el-form-item>
						<el-form-item label="变更时间" class="selFormItem" style="margin-top:2px">
							<el-date-picker class="selDateInput" v-model="contractTime" type="daterange"
								value-format="yyyy-MM-dd" range-separator="~" start-placeholder="开始日期"
								end-placeholder="结束日期">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
							v-if="showSearch">查询</el-button>
						<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div :style="siteContentViewHeight()">
			<el-card style="margin-top: -25px; height: 600px;" body-style="height:560px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="540" :row-class-name="tableRowClassName" class="dataListTable"
								:data="dataList" header-align="center" style="width: 100%;" border
								header-cell-class-name="stepPointTHeader" :cell-style="{ padding: '0px' }">
								<el-table-column type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column prop="contractName" label="合同名称" align="center" width="200"
									show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="changeItem" width="200" label="变更项目" align="center"
									show-overflow-tooltip>
								</el-table-column>
								<el-table-column prop="changeContent" width="250" label="变更内容" align="center">
								</el-table-column>
								<el-table-column prop="changeRsn" width="250" label="变更原因 " align="center">
								</el-table-column>
								<el-table-column prop="changeTime" label="变更时间" width="100" align="center">
								</el-table-column>
								<el-table-column label="变更" align="center">
									<el-table-column prop="changeWorkB" label="设备" align="center">
									</el-table-column>
									<el-table-column prop="changeWorkA" label="人" align="center">
									</el-table-column>
									<el-table-column prop="changeWorkC" label="工作量 " align="center">
									</el-table-column>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>

			</el-card>
		</div>
	</div>
</template>
<script>
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			showSearch: false,
			form: {},
			contractName: "",
			contractTime: [],
			contratStateList: [],
			stateFlg: "",
			dataList: [],
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
		};
	},
	components: {
	},
	mounted() {
		this.showSearch = $common.isAuth('ownerContract:change:search');
		this.getDataList();
	},
	methods: {
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return { height: height + 'px' }
		},
		handleSelectionChange(val) {
			// console.log(val)
			this.tableSelVal = val;
		},
		tableRowClassName({ row, rowIndex }) {
			let color = ''
			for (let item of this.tableSelVal) {
				if (item.id === row.id) color = 'table-SelectedRow-bgcolor'
			}
			return color
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/ownercontractchange/listBySubjectNo",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						subjectNo: $common.getItem("subjectNo"),
						beginTime: this.contractTime[0] ? this.contractTime[0] : "",
						endTime: this.contractTime[1] ? this.contractTime[1] : "",
						contractName: this.contractName,
						stateFlg: this.stateFlg,
						limit: this.pageSize,
						page: this.pageIndex
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex
					}
				});
			}
		},
		resetFormData() {
			this.contractName = "";
			this.contractTime = [];
			this.stateFlg = "";
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style lang="scss" scoped="scoped">
.selItemText {
	font-size: 14px;
	color: #1c1c1c;
	display: inline-block;
	width: 80px;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.selSearchInput .el-input__inner) {
	width: 150px !important;
	height: 30px !important;
}

.selDateInput {
	width: 280px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selDateInput .el-range-separator) {
	width: 40px !important;
	margin-top: -8px;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
